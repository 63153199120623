import React from "react";
import Link from "next/link";
import Image from "next/image";
import basePath from "lib/helpers/basePath";
import NoteCard from "components/common/NoteCard";
import EmbedScript from "components/LandingPage/RefiFormEmbedScript";
import { eligibilityPoints } from "DataMapper/Refi/LandingPageConstants";

function Eligibility() {
  return (
    <section
      className="py-[40px] md:py-[80px] px-[24px]"
      id="my-eligibility-id"
    >
      <div className="lg:grid md:grid grid-cols-2 md:px-[96px]">
        <div>
          <NoteCard text="F1, H1B &amp; OPT Visa holders are also eligible" />
          <h2 className="text-[#1B1D6A] text-[24px] leading-[28px] md:leading-[44px] md:text-[38px] font-bold my-3">
            Who can apply for Leap <br />
            Refinance?
          </h2>
          <h3 className="text-[18px] leading-6 text-[#000000D6] font-bold mt-[24px]">
            For Professionals
          </h3>
          {[
            ...eligibilityPoints,
            <p key="eligible-states">
              Staying in{" "}
              <span className="text-[#1B1D6A] underline underline-offset-4 cursor-pointer">
                <Link href="#supported-states-footer-id">Supported states</Link>
              </span>
            </p>,
          ].map((text, index) => (
            <div
              className="flex items-center my-2"
              key={`eligibility-${index}`}
            >
              <Image
                width={24}
                height={24}
                src={`${basePath}/assets/icons/green-tick-icon.svg`}
                alt="Tick Icon"
              />
              <span className="text-[17px] text-[#646464] ml-2 mt-1">
                {text}
              </span>
            </div>
          ))}
        </div>
        <EmbedScript />
      </div>
    </section>
  );
}

export default Eligibility;
