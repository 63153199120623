import { useEffect } from "react";

const EmbedScript = () => {
  const paperformId = process.env.PAPERFORM_ID ?? "";

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://paperform.co/__embed.min.js";
    document.body.appendChild(script);

    const styleElement = document.createElement("style");
    const styleSheet = document.createTextNode(`
        div[data-paperform-id="${paperformId}"] > iframe {
          max-height: 400px !important;
        }`);
    styleElement.appendChild(styleSheet);
    document.head.appendChild(styleElement);
  }, [paperformId]);

  return (
    <div data-paperform-id={paperformId} data-prefill-inherit="1" />
  );
};

export default EmbedScript;
